import { Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import SharedLayout from './layout/sharedLayout/SharedLayout';
import { PrivateRoute } from "./routes/PrivateRoute";
import { useDispatch } from "react-redux";
import { currentUser } from "./store/user/operations";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(currentUser());
  }, [dispatch]);
  const HomePage = lazy(() => import("./pages/homePage"));
  const Trauma = lazy(() => import("./pages/traumatology"));
  const Reanimation = lazy(() => import("./pages/reanimation"));
  const Therapy = lazy(() => import("./pages/therapy"));
  const Administration = lazy(() => import("./pages/administration"));
  const Infectious = lazy(() => import("./pages/infectious"));
  const Polyclinic = lazy(() => import("./pages/polyclinic"));
  const Neurology = lazy(() => import("./pages/neurology"));
  const Reception = lazy(() => import("./pages/reception"));
  const Surgery = lazy(() => import("./pages/surgery"));
  const Gynecology = lazy(() => import("./pages/gynecology"));
  const Laboratory = lazy(() => import("./pages/laboratory"));
  const NewsPage = lazy(() => import("./pages/newsPage"));
  const ContactsPage = lazy(() => import("./pages/contactsPage"));
  const AdminPage = lazy(() => import("./pages/adminPage"));
  const HistoryPage = lazy(() => import("./pages/historyPage"));
  const SertificatesPage = lazy(() => import("./pages/sertificatesPage"));
  const PublicInfoPage = lazy(() => import("./pages/publicInfoPage"));
  const UsefulInfo = lazy(() => import("./pages/usefulInfoPage"));
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/traumatology" element={<Trauma />}></Route>
          <Route path="/therapy" element={<Therapy />}></Route>
          <Route path="/administration" element={<Administration />}></Route>
          <Route path="/infectious" element={<Infectious />}></Route>
          <Route path="/polyclinic" element={<Polyclinic />}></Route>
          <Route path="/neurology" element={<Neurology />}></Route>
          <Route path="/reception" element={<Reception />}></Route>
          <Route path="/surgery" element={<Surgery />}></Route>
          <Route path="/gynecology" element={<Gynecology />}></Route>
          <Route path="/laboratory" element={<Laboratory />}></Route>
          <Route path="/reanimation" element={<Reanimation />}></Route>
          <Route path="/news" element={<NewsPage />}></Route>
          <Route path="/contacts" element={<ContactsPage />}></Route>
          <Route path="/usefulInfo" element={<UsefulInfo />}></Route>
          <Route
            path="/admin"
            element={
              <PrivateRoute
                redirectTo="/"
                element={<AdminPage />}
                allowedRoles={["ADMIN"]}
              />
            }
          />
          <Route path="/history" element={<HistoryPage />}></Route>
          <Route path="/sertificates" element={<SertificatesPage />}></Route>
          <Route path="/publicinfo" element={<PublicInfoPage />}></Route>
        </Route>
      </Routes>
    </Suspense>

  );
}

function Loading() {
  return (
    <div className="flex justify-center items-center sm:p-28 xl:p-[200px]">

      <span className="loader"></span>

    </div>
  );
}

export default App;
